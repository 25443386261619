import {deoBatch} from "@bestbuy/deo-client-sdk";

export default function deoLssActivation() {
	(function() {
		let userAttributes = window?.deoMetaData?.exp?.attrs || {};

		const deoExperimentDiv = 'data-exp-key';
		let deoActiveTests = document.querySelectorAll('[' + deoExperimentDiv + ']');

		if (deoActiveTests.length > 0) {
			const deoBatchArray = [];
			for (let i = 0; i < deoActiveTests.length; i++) deoBatchArray.push(deoActiveTests[i].getAttribute(deoExperimentDiv));

			let attributesArray = Object.entries(userAttributes)?.map(([key, value]) => ({ [key]: value }));

			deoBatch(deoBatchArray, attributesArray);
		}
	})();
}
